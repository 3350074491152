<template>
  <div>
    <!-- 拖拽栏 ，展示栏  -->
    <div class="model-view">
      <div class="model-view-menu">
        <p class="title">常用组件</p>
        <draggable class="model-view-menu-item" :move="handleMove" v-model="modelData" item-key="key" v-bind="{
            group: { name: 'model', pull: 'clone', put: true, animation: 150 },
            sort: false,
            ghostClass: 'ghost',
          }">
          <template #item="{ element }">
            <div v-if="!element.drawer">
              <li class="model-item" :key="element.key">
                <img :src="element.icon" class="icon" alt />
                <span>{{ element.name }}</span>
              </li>
            </div>
          </template>
        </draggable>

        <p class="title">图文内容</p>
        <draggable class="model-view-menu-item" :move="handleMove" v-model="modelData" item-key="key" v-bind="{
            group: { name: 'model', pull: 'clone', put: true, animation: 150 },
            sort: false,
            ghostClass: 'ghost',
          }">
          <template #item="{ element }">
            <div v-if="element.drawer">
              <li class="model-item" :key="element.key">
                <img :src="element.icon" class="icon"  alt />
                <span>{{ element.name }}</span>
              </li>
            </div>
          </template>
        </draggable>
      </div>

      <div class="model-view-content">
        <div class="content">
          <div class="wap-title">首页</div>
          <draggable class="draggable" group="model" ghostClass="ghost" @change="handleChange" :list="contentData.list" item-key="index">
            <template #item="{ element }">
              <div class="list" :key="element.index">
                <component class="component" :class="{ active: selected == element.index }" @click="handleComponent(element, element.index)" :is="templates[element.type]" :res="element.options" :attr='element'></component>

                <el-icon v-if="selected == element.index" @click="closeComponent(element.index)" color="#409EFC" class="close" size="25">
                  <circle-close-filled />
                </el-icon>
              </div>
            </template>
          </draggable>
        </div>
      </div>

      <!-- 右侧栏 -->
      <div class="model-config">
        <div style="display: flex; justify-content: right">
          <!-- <div style="margin-top: 10px; font-size: 12px; color: red">
            * 所有上传图片所标注的尺寸均为2倍设计稿
          </div> -->
          <el-button type="danger" size="large" @click="exportData">
            保存
          </el-button>
        </div>

        <decorate v-if="decorateData" :res="decorateData" ref="decorateRef" @refreshList="refreshList" @choose-style="handleDrawer" :meetingCode="meetingCode" :enterPriseCode="enterPriseCode" :linkEnterpriseCode="linkEnterpriseCode"></decorate>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
  markRaw,
  onActivated,
} from 'vue'

import { modelData } from './config.js'
import templates from './template/index'
import decorate from './decorate.vue'
import { editPage, getPageDetailData } from '@/api/page'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'MenuManagement',
  components: {
    Draggable: defineAsyncComponent(() => import('vuedraggable')),
    decorate,
  },

  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const state = reactive({
      modelData,
      contentData: {
        list: [],
      },
      decorateRef: null,
      templates,
      selected: 0,
      pageData: {},
      meetingCode: route.query.meetingCode,
      enterPriseCode: route.query.enterpriseCode,
      linkEnterpriseCode: route.query.linkEnterpriseCode,
      decorateData: {
        notAdd: true,
        options: {
          list: [],
        },
      },
    })

    // 监听自组件元素变化

    const handleMove = () => {
      return true
    }

    const handleContentlEnd = (evt) => {}

    const handleContentlAdd = (evt) => {}

    // 处理新增的元素
    const setPackage = (val, newIndex) => {
      val['index'] = newIndex
      proxy.contentData.list[newIndex] = val
      proxy.contentData.list = proxy.sortData(
        JSON.parse(JSON.stringify(proxy.contentData.list))
      )

      console.log(proxy.contentData)
    }

    // 选中组件
    const handleComponent = (ele, index) => {
      state.selected = index
      state.decorateData = ele
    }

    // 选择广告魔方分类
    // 右侧栏回调
    const handleDrawer = (val) => {
      let newIndex = state.selected
      val['index'] = newIndex
      // this.$set(this.contentData.list, newIndex, {
      //   ...val,
      //   options: {
      //     ...val.options,
      //   },

      //   // 绑定键值
      //   model: val.type,
      // })
      // this.contentData.list = JSON.parse(
      //   JSON.stringify(this.contentData.list)
      // )
      state.contentData.list[newIndex] = val
      state.decorateData = val
      // this.$set(this, 'decorateData', this.contentData.list[newIndex])
    }

    // 元素新增
    const handleChange = (evt) => {
      console.log(evt)
      // 新增元素
      if (evt.added) {
        const { newIndex, element } = evt.added
        let data = JSON.parse(JSON.stringify(element))
        proxy.setPackage(data, newIndex)
        proxy.handleComponent(data, newIndex)
      }
      // 元素排序
      if (evt.moved) {
        const { oldIndex, newIndex } = evt.moved
        proxy.selected = newIndex
        proxy.contentData.list = proxy.sortData(
          JSON.parse(JSON.stringify(proxy.contentData.list))
        )
      }
    }

    // 元素排序
    const sortData = (array) => {
      for (let i in array) {
        array[i].index = Number(i)
      }
      return array
    }

    // 删除元素
    const closeComponent = (index) => {
      proxy.$nextTick(() => {
        proxy.decorateData = ''
        // 如果当前楼层不为一
        if (proxy.contentData.list.length > 1) {
          // 如果当前最底层 给下一层赋
          if (index - 1 == -1) {
            proxy.handleComponent(proxy.contentData.list[index], index)
          } else {
            // 如果不是最底层给上一层赋值
            proxy.handleComponent(proxy.contentData.list[index - 1], index - 1)
          }
          proxy.contentData.list.splice(index, 1)
          proxy.contentData.list = proxy.sortData(
            JSON.parse(JSON.stringify(proxy.contentData.list))
          )
        } else {
          proxy.contentData.list.splice(index, 1)
        }
      })
    }

    onActivated(() => {
      fetchPageDetail()
    })

    const fetchPageDetail = async () => {
      if (!!proxy.$route.query.id) {
        const { data } = await getPageDetailData(proxy.$route.query.id)
        console.log(data)
        state.pageData = data
        if (data.pageData) {
          state.contentData = JSON.parse(data.pageData)
          state.decorateData = state.contentData.list[0]
          handleOldTabData()
          console.log(state.contentData, 'vvv111')
        } else {
          state.contentData = {
            list: [],
          }
          state.decorateData = {
            notAdd: true,
            options: {
              list: [],
            },
          }
        }
      } else {
        proxy.$baseMessage(
          `获取内容失败，请检查参数`,
          'error',
          'vab-hey-message-error'
        )
      }
    }
    const handleOldTabData = () => {
      console.log(state.contentData, '====>>>>')
      state.contentData.list.forEach((v) => {
        v.hasOwnProperty('attributeParams') ? '' : (v.attributeParams = {})
      })
    }
    const exportData = async () => {
      console.log(state.contentData)
      let obj = {
        pageData: JSON.stringify(proxy.contentData),
        id: state.pageData.id,
        name: state.pageData.name,
        pageType: state.pageData.pageType,
      }
      const { data } = await editPage(obj)
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
    }

    // 刷新数据
    const refreshList = (val) => {
      proxy.decorateData = val
    }
    return {
      ...toRefs(state),
      handleMove,
      handleContentlEnd,
      handleContentlAdd,
      handleChange,
      handleComponent,
      handleDrawer,
      exportData,
      closeComponent,
      sortData,
      refreshList,
      setPackage,
      fetchPageDetail,
    }
  },
})
</script>

<style lang="scss" scoped>
.model-view {
  display: flex;
  height: 80vh;
  .model-view-menu {
    flex: 1.5;
    min-width: 250px;
    .title{
      font-weight: bold;
      font-size: 16px;
      margin-left: 10px;
    }
    > .model-view-menu-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      color: #2e2e2e;
      .icon{
        width: 30px;
        height: 30px;
      }
    }
  }
  .model-config {
    flex: 4.5;
    overflow-y: auto;
    overflow-x: auto;
  }
  .model-view-content {
    flex: 4;
  }
  .model-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: 0 5px 10px 5px;
    font-size: 13px;
    line-height: 1.75;
    background: #f6f6f9;
    border: 1px solid #ededed;
  }
}

.content {
  width: 360px;
  height: 780px;
  padding: 50px 13px;
  margin: 20px 0;
  overflow: hidden;
  background: url('../../assets/iPhoneX_model.png') no-repeat;
  background-size: 360px;
  > .component,
  .draggable {
    height: 590px;
    overflow-y: auto;
    background: #ebebeb;
  }
  > .draggable {
    padding-bottom: 100px;
  }
}
.list {
  position: relative;
}
.close {
  position: absolute;
  top: -5px;
  right: 5px;
  z-index: 99;
}
.model-view-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f6f6f9;
  border-radius: 0.8em;
}
.wap-title {
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-bottom: 1px solid #ededed;
}

.active {
  box-sizing: border-box;
  border: 1px solid red;
}
</style>
